<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_7 flex-row">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd9950c3c6e4bb1e930ec47e78c813ac81c89a7bee475d1239718ec59ca1e6a31.png"
        />
        <span class="text_1">Ágil Finanzas</span>
        <span class="text_2" @click="gopage(1)">INICIO</span>
        <span class="text_3" @click="gopage(2)">Acerca de Nosotros</span>
        <span class="text_4" @click="gopage(3)">Política privacidad</span>
        <span class="text_5" @click="gopage(4)">Centro de Ayuda</span>
      </div>
      <div class="block_8 flex-row"><div class="group_1 flex-col"></div></div>
      <div class="text-wrapper_8 flex-row"><span class="text_6">Centro de Ayuda</span></div>
      <div class="text-wrapper_9 flex-row">
        <span class="text-wrapper_2"></span>
      </div>
    </div>
    <div class="box_4 " v-for="(item,index) in question " :key="index">
      
      
      <div class="block_1 " >
        <div class="text-wrapper_10" >
          <div class="text_9">{{ item.header }}</div>
          <div class="quertionbox" v-for="(it,key) in item.chrih " :key="key">
            <div class="quertionheader" @click="it.isshow = !it.isshow">
              <img
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng406da0344af6ac5bfc3b879a82de69e2f8d5e57d3ae01414f075995f7d06af83.png"
            />
             <span class="header">{{ it.header }}</span>
              <img class="arrow" src="./assets/img/down.png" alt="" v-if="!it.isshow">
              <img class="arrow" src="./assets/img/up.png" alt="" v-else>
            </div>
            <div class="quertiontext" v-html="it.ask" v-if="it.isshow">
              
            </div>
          </div>
            
        </div>
        
      </div>
    
    </div>
    <div class="box_3 flex-col">
      <div class="text-wrapper_13 flex-row">
        <span class="text_20">Ágil Finanzas</span>
        <span class="text_21">Contáctanos</span>
        <span class="text_22">DIRECCIÓN</span>
      </div>
      <div class="group_7 flex-row">
        <div class="text-wrapper_7">
          <span class="paragraph_2">
            <span @click="gopage(1)">Inicio
              <img
       
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
            </span> 
            <br />
            <span @click="gopage(2)">Acerca de Nosotros
              <img
       
       referrerpolicy="no-referrer"
       src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
     />
            </span> 
            <br />
            <span @click="gopage(3)">
              Política de privacidad

              <img
       
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
            </span>
            <br />
            <span class="" @click="gopage(4)">Centro de Ayuda
            
              <img
       
       referrerpolicy="no-referrer"
       src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
     /></span>
            </span>
        </div>
       
        <div class="group_8 flex-col justify-between">
          
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfe4a82261da913a31b60b0e4e8a6f579de869c412f3f7f355954aa8eb38f8329.png"
            />
            <span class="text-group_4">Correo Electrónico: kljkdfg435546@gmail.com</span>
          </div>
        </div>
        <div class="image-text_7 flex-row justify-between">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng76f69a10cfde10bc273937827fddef127065d252638b93b0a6de1f11b642bbee.png"
          />
          <span class="text-group_5">
            C 23 54 4 H COL SANTA CRUZ AVIACION <br> 15640 VENUSTIANO CARRANZA,D.F.
          </span>
        </div>
      </div>
      <div class="group_9 flex-row">
        <div class="image-text_8 flex-row justify-between">
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng94cd62c6e69ecfe69637915271cf66036dc4783acae82b225ff35b17954bdff0.png"
          />
          <span class="text-group_6">Horario de Atención al Cliente: 9:00 - 18:00</span>
        </div>
      </div>
      
     
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      question:[
        {
          header:'Problemas con la cuenta',
          chrih:[
            {
              isshow:true,
            header:'¿Cómo registrarse?',
            ask:'Necesita ingresar su número de teléfono móvil, hacer clic en "Obtener código de verificación", luego ingresar el código recibido y configurar una contraseña para completar el registro.',
          },
          {
            isshow:false,
            header:'¿Cómo cancelar la cuenta?',
            ask:'Puede elegir entre los siguientes métodos para cancelar su cuenta:<br><br> <strong style="color:#000">1、Contactar al servicio al cliente:</strong> <br>Si desea cancelar su cuenta, por favor contacte a nuestro equipo de servicio al cliente kljkdfg435546@gmail.com. Ellos le proporcionarán más orientación y ayuda.<br><br> <strong style="color:#000">2、A través de la aplicación:</strong><br> - Abra la aplicación Ágil Finanzas.<br>- Vaya al centro personal, generalmente ubicado en la página principal o en el menú lateral de la aplicación.<br>- En la página de configuración, busque la opción "Eliminar cuenta" u opciones similares.<br>- Siga las instrucciones para completar la cancelación de la cuenta.<br><br>Estos pasos le ayudarán a cancelar su cuenta de Ágil Finanzas de manera segura. Si tiene alguna pregunta o necesita más ayuda, no dude en contactar a nuestro equipo de servicio al cliente.'
          },
        ]
        },

        {
          header:'Contáctenos',
          chrih:[
            {
              isshow:false,
            header:'¿Cómo contactarnos?',
            ask:'Si tiene algún problema, contáctenos a través de nuestro servicio al cliente.<br>Correo electrónico de servicio al cliente: kljkdfg435546@gmail.com<br>Horario de trabajo: lunes a domingo de 9:00 a 18:00.',
          },
          {
            isshow:false,
            header:'¿Cómo presentar una queja?',
            ask:'Puede contactar a nuestro servicio al cliente, explicar el motivo de su reclamo y proporcionar la evidencia correspondiente. Puede compartir información sobre el préstamo, como el nombre del cliente, la fecha del préstamo, el monto solicitado, entre otros. Haremos nuestro mejor esfuerzo para ayudarlo a resolver el problema.'
          },
        ]
        }
      ]
    };
  },
  methods: {
    gopage(type){
      switch (type) {
        case 1:
          this.$router.push('./page_1')
          break;
          
          case 2:
          this.$router.push('./page_2')
          break;
          
          case 3:
          this.$router.push('./page_3')
          break;

          case 4:
          this.$router.push('./page_4')
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />