import Vue from 'vue'
import VueRouter from 'vue-router'
import page_2 from '../views/page_2/index.vue'
import page_3 from '../views/page_3/index.vue'
import page_4 from '../views/page_4/index.vue'
import page_1 from '../views/page_1/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/page_1"
  },
  {
    path: '/page_2',
    name: 'page_2',
    component: page_2
  },
  {
    path: '/page_3',
    name: 'page_3',
    component: page_3
  },
  {
    path: '/page_4',
    name: 'page_4',
    component: page_4
  },
  {
    path: '/page_1',
    name: 'page_1',
    component: page_1
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
