<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="group_2 flex-row">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd9950c3c6e4bb1e930ec47e78c813ac81c89a7bee475d1239718ec59ca1e6a31.png"
        />
        <span class="text_1">Ágil Finanzas</span>
        <span class="text_2" @click="gopage(1)">INICIO</span>
        <span class="text_3" @click="gopage(2)">Acerca de Nosotros</span>
        <span class="text_4" @click="gopage(3)">Política privacidad</span>
        <span class="text_5" @click="gopage(4)">Centro de Ayuda</span>
      </div>
      <div class="group_3 flex-row"><div class="block_1 flex-col"></div></div>
      <div class="text-wrapper_1 flex-row"><span class="text_6">Sobre nosotros</span></div>
      <div class="text-wrapper_2 flex-row"><span class="text_7">Ágil Finanzas, tu socio financiero de confianza.</span></div>
    </div>
    <div class="group_4 flex-col">
      <span class="text_8">Nuestra&nbsp;ventaja</span>
      <div class="list_1 flex-row">
        <div
          class="list-items_1 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData0"
          :key="index"
        >
          <img class="image_1" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
          <span
            v-if="item.slot2 === 2"
            class="text_9"
            :style="{ color: item.specialSlot2.lanhufontColor0 }"
            v-html="item.specialSlot2.lanhutext0"
          ></span>
          <span
            v-if="item.slot1 === 1"
            class="text_10"
            :style="{ color: item.specialSlot1.lanhufontColor0 }"
            v-html="item.specialSlot1.lanhutext0"
          ></span>
        </div>
      </div>
      <div class="block_2 flex-row justify-between">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd4f38e3469bb49a40c0e63abf1418dd8e974bd1cd5271124f0b4f2ae9602b6fb.png"
        />
        <div class="text-wrapper_3 flex-col justify-between">
          <span class="text_11">Sobre Ágil Finanzas</span>
          <span class="paragraph_1">
            Ágil Finanzas es una plataforma líder de servicios financieros móviles, comprometida en proporcionar soluciones de préstamos pequeños que son eficientes, convenientes y seguras para los usuarios. 
<br><br>
Utilizando análisis avanzados de big data y tecnología de algoritmos inteligentes, Ágil Finanzas personaliza soluciones financieras exclusivas para cada usuario, ayudándoles a manejar sus necesidades financieras a corto plazo con confianza.
<br><br>



          </span>
        </div>
      </div>
      <div class="block_3 flex-row justify-between">
        <div class="text-wrapper_4 flex-col justify-between">
          <span class="text_12">Solicitud de préstamos simplificada</span>
          <span class="paragraph_2">
            A través de la aplicación Ágil Finanzas, los usuarios no solo obtienen servicios financieros convenientes, sino que también experimentan la eficiencia y la confianza que la integración de la tecnología moderna con las finanzas puede ofrecer.
<br><br>
Solo necesita responder algunas preguntas dentro de la aplicación y enviarlas. Las solicitudes son aprobadas en cuestión de minutos. Una vez aprobado su préstamo, los fondos se depositan directamente en su cuenta bancaria.
          </span>
        </div>
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng561efd4bc34a173ec15a6da1b05bdbe2a731b5fa3195f0423189ab5f842f6e6f.png"
        />
      </div>
    </div>
    <div class="group_5 flex-col">
      <div class="text-wrapper_5 flex-row">
        <span class="text_13">Ágil Finanzas</span>
        <span class="text_14">Contáctanos</span>
        <span class="text_15">DIRECCIÓN</span>
      </div>
      <div class="section_1 flex-row">
        <div class="text-wrapper_6">
          <span class="paragraph_3">
            <span @click="gopage(1)">Inicio</span> 
            <br />
            <span @click="gopage(2)">Acerca de Nosotros</span> 
            <br />
            <span @click="gopage(3)">
              Política de privacidad
            </span>
            <br />
            <span class="" @click="gopage(4)">Centro de Ayuda</span>
           </span>
        </div>
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
        />
        <div class="section_2 flex-col justify-between">
          
          <div class="image-text_2 flex-row justify-between">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfe4a82261da913a31b60b0e4e8a6f579de869c412f3f7f355954aa8eb38f8329.png"
            />
            <span class="text-group_2">Correo Electrónico: kljkdfg435546@gmail.com</span>
          </div>
        </div>
        <div class="image-text_3 flex-row justify-between">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng76f69a10cfde10bc273937827fddef127065d252638b93b0a6de1f11b642bbee.png"
          />
          <span class="text-group_3">
            C 23 54 4 H COL SANTA CRUZ AVIACION <br> 15640 VENUSTIANO CARRANZA,D.F.
          </span>
        </div>
      </div>
      <div class="section_3 flex-row">
        <div class="image-text_4 flex-row justify-between">
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng94cd62c6e69ecfe69637915271cf66036dc4783acae82b225ff35b17954bdff0.png"
          />
          <span class="text-group_4">Horario de Atención al Cliente: 9:00 - 18:00</span>
        </div>
      </div>
      <img
        class="thumbnail_2"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
      <img
        class="thumbnail_3"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
      <img
        class="thumbnail_4"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0: 'rgba(244,247,255,1.000000)',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb71faa3e47c335d17d8f0bc39d5444ca7ee3ad6953512856e651070303bc9679',
          specialSlot1: {
            lanhutext0: 'Ofrecemos una variedad de opciones de préstamos para satisfacer diferentes necesidades financieras.',
            lanhufontColor0: 'rgba(61,124,255,1)'
          },
          slot1: 1
        },
        {
          lanhuBg0: 'rgba(241,252,245,1.000000)',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdf730c5d052a6def4b4cb5e67d4873b0ec9cb6912fd1b3bb4a4ba93e8f61b719',
          specialSlot1: {
            lanhutext0: 'Aprobación rápida para que obtengas soporte financiero inmediato.',
            lanhufontColor0: 'rgba(57,201,78,1)'
          },
          slot1: 1
        },
        {
          lanhuBg0: 'rgba(254,248,236,1.000000)',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng84a9a60235a80d6bd5675ebe7d8cf18935a24d921fa737ae0c67743ec3a948bd',
          specialSlot2: {
            lanhutext0: 'Nos guiamos por el principio de poner al usuario primero, lo que nos ha valido la confianza y el reconocimiento de nuestros usuarios.',
            lanhufontColor0: 'rgba(248,167,8,1)'
          },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(247,246,255,1.000000)',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng917d886b766a2efdcfc0649ca8ba1816905ea820e5d62085c16193123c2f59dc',
          specialSlot2: {
            lanhutext0: 'Atendemos a una amplia gama de clientes, satisfaciendo las diversas necesidades financieras de diferentes grupos de personas.',
            lanhufontColor0: 'rgba(124,116,255,1)'
          },
          slot2: 2
        }
      ],
      constants: {}
    };
  },
  methods: {
    gopage(type){
      switch (type) {
        case 1:
          this.$router.push('./page_1')
          break;
          
          case 2:
          this.$router.push('./page_2')
          break;
          
          case 3:
          this.$router.push('./page_3')
          break;

          case 4:
          this.$router.push('./page_4')
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />