<template>
  <div class="page flex-col">
    <div class="group_5 flex-col">
      <div class="block_22 flex-row">
        <img
          class="label_11"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd9950c3c6e4bb1e930ec47e78c813ac81c89a7bee475d1239718ec59ca1e6a31.png"
        />
        <span class="text_1">Ágil& Finanzas</span>
        <span class="text_49" @click="gopage(1)">INICIO</span>
        <span class="text_50" @click="gopage(2)">Acerca de Nosotros</span>
        <span class="text_51" @click="gopage(3)">Política privacidad</span>
        <span class="text_52" @click="gopage(4)">Centro de Ayuda</span>
      </div>
      <div class="block_23 flex-row"><div class="group_6 flex-col"></div></div>
      <div class="block_24 flex-row">
        <div class="text-group_39 flex-col justify-between">
          <span class="text_6">Ágil Finanzas - Más rápido,<br> más fácil, más seguro</span>
          <span class="text_53">
            Plataforma financiera en línea 100% segura y confiable, que le ofrece un servicio rápido, conveniente y seguro.
          </span>
        </div>
      </div>
      <div class="block_25 flex-row">
        <div class="block_17 flex-row" @click="getapp()">
          <div class="image-text_25 flex-row justify-between" >
            <img
              class="label_12"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2e85b1d9a2e6a46764714229be73c0a276ed63aa2a2cd9293639dbd4eee8aa73.png"
            />
            <span class="text-group_31" >GET&nbsp;IT&nbsp;ON</span>
          </div>
          <img
            class="image_10"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng037f3e7d6c0872b8bcd59c424b7d497fa11ba3424f0ff97546cb8ba0894b29ac.png"
          />
        </div>
      </div>
      <div class="block_26 flex-row"><div class="block_18 flex-col"></div></div>
      <div class="group_15 flex-col"></div>
      <div class="image_12 flex-col">
        <img
          class="image_27"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6cbce4f47a4209314b20ac7d5a753fc8cbbc79bf69ee9250a8d5d6884580f525.png"
        />
        <img
          class="image_28"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng2e93fbc71c6168a633a31b84bee4e0d462ee422b9dad065f264ece94d9bf3113.png"
        />
      </div>
    </div>
    <div class="group_16 flex-col">
      <span class="text_16">Ventajas de Ágil Finanzas</span>
      
      
      <div class="block_27 flex-row justify-between">
        <div class="box_19 flex-col">
          <img
            class="image_13"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng5851ca43882159323cc5ad4ca3a8b708860a8b6923967742e6873c79c225f1bb.png"
          />
          <span class="text_55">Aprobación rápida</span>
          <span class="text_55_1">Plataforma de revisión en línea inteligente, que completa la aprobación en tan solo 30 segundos.</span>
        </div>
        <div class="box_20 flex-col">
          <img
            class="image_14"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8ec1e7f3b306fe198848ea099328b060563d1e4bc535d386dd3e4dd347b82fc8.png"
          />
          <span class="text_56">Seguro y confiable</span>
          <span class="text_56_1">Protección total de la privacidad de los datos personales, 100% segura.</span>
        </div>
        <div class="box_21 flex-col">
          <img
            class="image_15"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng60ecd3f1ce590ed455393a9e49a4b740f2d0c36d8783d7525fac5384b2945e32.png"
          />
          <span class="text_57">Montos de préstamo flexibles</span>
          <span class="text_57_1">Elija diferentes montos de préstamo y plazos de pago según sus necesidades.</span>
          
        </div>
        <div class="box_22 flex-col">
          <img
            class="image_16"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngee8430c6d8718a732a45d9f57b79196d5aea2ca4a40fd876d96768e777dbd3f6.png"
          />
          <span class="text_58">Adecuado para todo tipo de personas</span>
          <span class="text_58_1">Sin importar a qué se dedique, puede solicitar un préstamo a través de Ágil Finanzas.</span>
        </div>
      </div>
      <div class="block_28 flex-row justify-between">
        <div class="image-wrapper_4 flex-col"><div class="section_16 flex-col"></div></div>
        <div class="text-wrapper_15 flex-col justify-between">
          <span class="text_59">Sobre Ágil Finanzas</span>
          <span class="paragraph_2">
            Ágil Finanzas es una plataforma líder de servicios financieros móviles, dedicada a ofrecer soluciones de préstamos pequeños que son eficientes, convenientes y seguros para los usuarios.
            <br><br>
Utilizando análisis avanzados de grandes datos y tecnología de algoritmos inteligentes, Ágil Finanzas crea soluciones financieras personalizadas para cada usuario, ayudándolos a enfrentar con tranquilidad sus necesidades financieras a corto plazo.
<br><br>
A través de la APP de Ágil Finanzas, los usuarios no solo obtienen servicios financieros convenientes, sino que también experimentan la eficiencia y la confianza que la fusión de la tecnología moderna con las finanzas puede ofrecer.
          </span>
        </div>
      </div>
      <div class="section_17 flex-row">
        <div class="text-group_40 flex-col justify-between">
          <span class="text_60">Ágil Finanzas: <br> Obtén $20,000 fácilmente</span>
          <span class="text_61">
            Aprobación rápida, préstamos flexibles, te ayudamos a resolver tus necesidades financieras sin complicaciones.
          </span>
        </div>
      </div>
      <span class="text_62">¿Cómo usar Ágil Finanzas?</span>
      <div class="image-wrapper_9 flex-row justify-between">
        <img
          class="image_18"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPnga0500724272082bbd136cc14f3d66235c924cb9d6e56aae0470e5375c362ce15.png"
        />
        <img
          class="image_19"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5e6032daeba0e38954051ddff3bdc00aca30da1c97fa333c04947feac03914ce.png"
        />
      </div>
      <div class="text-wrapper_16 flex-row justify-between">
        <span class="text_63">Descarga la aplicación Ágil Finanzas</span>
        <span class="text_64">Solicita 100% en línea, en cualquier momento y lugar</span>
      </div>
      <div class="block_29 flex-row">
        <div class="box_31 flex-col justify-between">
          <img
            class="image_20"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnge0bc013ca68d463e0157514ca4430e7d6b7a3dad8f1f10c36b66c1f9b43a2244.png"
          />
          <span class="text_65">Pasos sencillos, completa la solicitud en 3 pasos</span>
        </div>
        <div class="box_32 flex-col justify-between">
          <img
            class="image_21"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngef6aa7aac62fca4ef289f7805120ecc494a48003bcb447343fb536f37c826496.png"
          />
          <span class="text_66">
            Aprobación rápida, fondos depositados inmediatamente
          </span>
        </div>
        <div class="image-wrapper_6 flex-col"><div class="section_19 flex-col"></div></div>
      </div>
      <div class="box_28 flex-col">
        <img
          class="image_29"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd5eceb26f50930d779fc0581b0a4be801be22a27f76ae8740de927177632e4dd.png"
        />
      </div>
      <img
        class="image_30"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng1356cf726c4f412a8b00dac1f79980f6cd1e81cb7db4345b6878b183253e712a.png"
      />
      <div class="image_24 flex-col">
        <img
          class="image_31"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngaeab807d0f1b4104bc6d707feec49bf313d17042d739527fc5324831a8035012.png"
        />
      </div>
      <img
        class="image_32"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng1c08d28a43b2b9f733ec69505de8d2b38469b06f343f52f2796b1f058b6c68b3.png"
      />
    </div>
    <div class="group_8 flex-col">
      <div class="text-wrapper_17 flex-row">
        <span class="text_67">Ágil Finanzas</span>
        <span class="text_68">Contáctanos</span>
        <span class="text_69">DIRECCIÓN</span>
      </div>
      <div class="section_21 flex-row">
        <div class="text-wrapper_11">
          <span class="paragraph_3">
           <span @click="gopage(1)">Inicio</span> 
            <br />
            <span @click="gopage(2)">Acerca de Nosotros</span> 
            <br />
            <span @click="gopage(3)">
              Política de privacidad
            </span>
            <br />
            <span class="" @click="gopage(4)">Centro de Ayuda</span>
          </span>
          
        </div>
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
        />
        <div class="section_22 flex-col justify-between">
         
          
          <div class="image-text_27 flex-row justify-between">
            <img
              class="image_25"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfe4a82261da913a31b60b0e4e8a6f579de869c412f3f7f355954aa8eb38f8329.png"
            />
            <span class="text-group_34">Correo Electrónico: kljkdfg435546@gmail.com</span>
          </div>
        </div>
        <div class="image-text_28 flex-row justify-between">
          <img
            class="image_26"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng76f69a10cfde10bc273937827fddef127065d252638b93b0a6de1f11b642bbee.png"
          />
          <span class="text-group_35">
            C 23 54 4 H COL SANTA CRUZ AVIACION <br> 15640 VENUSTIANO CARRANZA,D.F.
          </span>
        </div>
      </div>
      <div class="section_23 flex-row">
        <div class="image-text_29 flex-row justify-between">
          <img
            class="label_14"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng94cd62c6e69ecfe69637915271cf66036dc4783acae82b225ff35b17954bdff0.png"
          />
          <span class="text-group_36">Horario de Atención al Cliente: 9:00 - 18:00</span>
        </div>
      </div>
      <img
        class="thumbnail_2"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
      <img
        class="thumbnail_3"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
      <img
        class="thumbnail_4"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    getapp(){
      window.open('https://play.google.com/store/apps/details?id=com.liga.cefinan')
    },
    gopage(type){
      switch (type) {
        case 1:
          this.$router.push('./page_1')
          break;
          
          case 2:
          this.$router.push('./page_2')
          break;
          
          case 3:
          this.$router.push('./page_3')
          break;

          case 4:
          this.$router.push('./page_4')
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />